.container {
  border: none;
  background: transparent;
  outline: none;
  cursor: pointer;
  text-align: left;
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  color: #3474d3;

  svg {
    color: grey;
    flex-shrink: 0;
  }

  :global(.text) {
    margin-left: 10px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &.critical {
    color: #f34747;
    svg {
      color: #f34747;
    }
  }
}
