.input-tags {
  font-size: 12px;

  :global(.MuiInputBase-input) {
    padding: 8px 12px;
    height: 24px;
    font-size: 16px;
  }

  :global(.MuiFormLabel-root) {
    top: -7px;
  }

  :global(.MuiInputLabel-shrink) {
    top: 0;
  }

  :global(.MuiInputAdornment-root) {
    svg {
      color: var(--mui-palette-primary-main);
      height: 25px;
      width: 25px;
      cursor: pointer;
    }

    .icon_disabled {
      color: var(--mui-palette-grey-50);
      cursor: initial;
    }
  }

  :global(.MuiChip-root) {
    background: var(--mui-palette-primary-light);
    color: var(--mui-palette-common-white);

    svg {
      color: var(--mui-palette-common-white);
    }
  }

  .stack-container {
    flex-direction: row;
    flex-wrap: wrap;
    gap: 8px;

    &_mt {
      margin-top: 8px;
    }
  }
}
