.buttonBase {
  .menuLogo {
    img {
      height: 100%;
    }
    .logoContainer {
      width: 80.282px;
      height: 18px;
      img {
        width: 100%;
      }
    }
  }
}
