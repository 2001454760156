.view-container {
  margin-top: 40px;
}

.actions {
  display: flex;

  &-icon {
    margin-right: 8px;
    display: flex;
    align-items: center;
  }

  &-text {
    display: flex;
    align-items: center;
  }

  &-btn {
    margin-left: 16px;
  }
}

.icon {
  margin-left: 5px;
  transform: rotate(-180deg);

  &-reverse {
    margin-left: 5px;
    transform: rotate(0);
  }
}

.report-id-container {
  display: flex;
  align-items: center;
}
