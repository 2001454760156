.custom-input {
  font-size: 12px;
  :global(.MuiInputBase-input) {
    padding: 8px 12px;
    height: 24px;
    font-size: 16px;
  }
  :global(.MuiFormLabel-root) {
    top: -7px;
  }
  :global(.MuiInputLabel-shrink) {
    top: 0;
  }
  :global(.MuiInputAdornment-root) {
    svg {
      color: var(--mui-palette-primary-main);
      height: 20px;
      width: 20px;
      cursor: pointer;
    }
  }
}

.DatePickerRoot {
  :global(.react-datepicker) {
    border-style: none;
    padding: 16px;
    border-radius: 8px;
    background-color: var(--mui-palette-grey-50);
    box-shadow: 0 8px 20px var(--mui-palette-grey-400);
  }

  :global(.react-datepicker__header) {
    background-color: var(--mui-palette-grey-50);
    border-style: none;
    padding: 0;
    border-radius: 0;

    &__dropdown {
      padding: 0 4px;
      text-align: start;
      text-transform: capitalize;

      :global(.react-datepicker__month-read-view--selected-month),
      :global(.react-datepicker__year-read-view--selected-year) {
        font-size: 16px;
        font-weight: 600;
      }
    }
  }

  :global(.react-datepicker-popper) {
    z-index: 3;

    &[data-placement^='bottom'] {
      padding-top: 5px;
    }

    &[data-placement^='top'] {
      padding-bottom: 0;
    }
  }

  :global(.react-datepicker__current-month) {
    display: none;
  }

  :global(.react-datepicker__month-read-view),
  :global(.react-datepicker__year-read-view) {
    display: flex;
    position: relative;
    padding: 8px 4px;
    border-radius: 8px;

    &:hover {
      background-color: var(--mui-palette-grey-100);
    }
  }

  :global(.react-datepicker__year-read-view--down-arrow),
  :global(.react-datepicker__month-read-view--down-arrow) {
    position: inherit;
    align-self: flex-end;
    display: none;
  }

  :global(.react-datepicker__day-names) {
    background-color: var(--mui-palette-grey-50);
    margin: 0;
    font-size: 14px;
  }

  :global(.react-datepicker__month) {
    margin: 0;
  }

  :global(.react-datepicker__day-name) {
    width: 36px;
    height: 36px;
    margin: 2px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    color: var(--mui-palette-grey-700);
    text-transform: capitalize;

    &:nth-last-of-type(-n + 2) {
      color: var(--mui-palette-error-main);
    }
  }

  :global(.react-datepicker__day) {
    width: 36px;
    height: 36px;
    border-radius: 8px;
    background-color: var(--mui-palette-grey-50);
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin: 2px;
    color: var(-mui-palette-grey-800);
    font-size: 14px;

    &--today {
      outline: var(--mui-palette-grey-700) solid 1px;
    }

    &--selected {
      background-color: var(--mui-palette-primary-main);
      color: var(--mui-palette-common-white);
    }

    &:hover {
      background-color: var(--mui-palette-grey-100);
      color: var(--mui-palette-common-black);
    }

    &--selected:hover {
      background-color: var(--mui-palette-grey-100);
    }

    &--disabled {
      color: var(--mui-palette-grey-400);

      &:hover {
        color: var(--mui-palette-grey-400);
        background: none;
      }
    }

    &--highlighted {
      color: var(--mui-palette-primary-dark);
    }

    &:focus-visible {
      outline: none;
    }

    &--keyboard-selected:focus-visible,
    &--keyboard-selected {
      &:global(.react-datepicker__day--disabled) {
        outline: var(--mui-palette-grey-100) auto 1px;
      }
    }

    &--outside-month {
      visibility: hidden;
    }
  }

  :global(.react-datepicker__month-dropdown-container),
  :global(.react-datepicker__year-dropdown-container) {
    margin: 0;
  }

  :global(.react-datepicker__year-dropdown),
  :global(.react-datepicker__month-dropdown) {
    background-color: var(--mui-palette-grey-50);
    border-radius: 8px;
    box-shadow: 0 2px 12px var(--mui-palette-grey-100);
    border-style: none;
    width: 60%;
    left: 0;
    top: 0;
  }

  :global(.react-datepicker__navigation) {
    color: var(--mui-palette-grey-700);

    &:hover {
      opacity: 0.5;
    }

    &--previous {
      right: 52px;
      top: 21px;
      left: unset;
    }

    &--next {
      right: 16px;
      top: 21px;
    }

    &-icon {
      top: 0;

      &::before {
        content: '';
        border-width: 2px 2px 0 0;
        height: 6px;
        width: 6px;
        border-color: unset;
      }

      &--previous {
        right: 2px;
      }

      &--next {
        left: 2px;
      }
    }
  }

  :global(.react-datepicker__month-option),
  :global(.react-datepicker__year-option) {
    padding: 8px 12px;
    box-sizing: border-box;
    border-radius: 8px;

    &:hover {
      background-color: var(--mui-palette-grey-100);
    }
  }

  :global(.react-datepicker__year-option) {
    margin: 2px 0 2px 4px;
    width: auto;
  }

  :global(.react-datepicker__month-option--selected_month),
  :global(.react-datepicker__year-option--selected_year) {
    background-color: var(--mui-palette-primary-main);
    color: var(--mui-palette-common-white);

    &:hover {
      background-color: var(--mui-palette-100);
      color: var(--mui-palette-common-black);
    }
  }

  :global(.react-datepicker__month-option--selected),
  :global(.react-datepicker__year-option--selected) {
    display: none;
  }

  :global(.react-datepicker__month-dropdown) {
    padding: 8px;
    overflow: hidden;
    width: fit-content;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 4px;
  }

  :global(.react-datepicker__year-dropdown--scrollable) {
    padding: 8px 0;
    overflow-y: auto;
    overflow-x: hidden;
    height: 40 * 6;

    &::-webkit-scrollbar {
      width: 16px;
    }

    &::-webkit-scrollbar-track {
      background-color: transparent;
    }

    &::-webkit-scrollbar-thumb {
      background-color: var(--mui-palette-100);
      border: 4px solid var(--mui-palette-grey-50);
      border-radius: 8px;
    }
  }
}
