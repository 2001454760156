.emptyFilters {
  display: flex;
  min-height: 42px;
  padding: 8px;
  border-radius: 8px;
  align-items: center;
  justify-content: center;
}

.historyGrid {
  display: grid;
  grid-template-columns: 250px 7fr 2fr;
  gap: 10px;
  min-height: 42px;
  padding: 8px;
  border-radius: 8px;
  align-items: center;
  &:hover {
    cursor: pointer;
    background: var(--info-states-selected, rgba(0, 87, 178, 0.08));
    .filterHistoryActions {
      display: flex;
      justify-content: flex-end;
      gap: 5px;
    }
  }

  .filterHistoryActions {
    display: none;
  }

  .filters {
    display: flex;
    flex-wrap: wrap;
    overflow: hidden;
    gap: 5px;
  }

  .chipWrap {
    max-width: 100%;
  }
  .name {
    overflow-wrap: break-word;
  }
}
