@import '../../variables';

.layout {
  .nav {
    grid-area: nav;
  }
  .main {
    grid-area: main;
  }
  .page {
    height: auto;
    position: relative;
    padding: 24px;
    box-sizing: border-box;
    &__content {
      border: 1px solid $light-grey-color;
      box-shadow: $block-shadow;
      position: relative;
      background-color: var(--mui-palette-common-white);
      padding: 20px;
      box-sizing: border-box;
    }
  }
}
