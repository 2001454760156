body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  /* stylelint-disable property-no-vendor-prefix */
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
/* stylelint-disable selector-no-qualifying-type */
input[type='number'] {
  -moz-appearance: textfield;
}

.spinnerWrap {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.uploadBtn {
  span {
    width: 50px;
    height: 48px;
    padding: 0;
  }
}

.fileLinkWrap {
  width: 80px;
  align-items: center;
  display: flex;
  justify-content: center;
  margin-right: 10px;
}

.hiddenHeaderTable {
  div[role='table'] > div:nth-child(1) {
    display: none;
  }
}

.pagination {
  margin-top: 15px;
  display: flex;
  justify-content: center;
}

.productViewTable {
  margin-left: -30px;
  margin-top: -30px;
  margin-right: -30px;

  h2 {
    font-size: 20px;
    text-align: center;
    margin-top: 50px;
  }
}

.flexLine {
  display: flex;
  align-items: center;
}

.uploadBtnWrap {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.printButton {
  display: flex;
  justify-content: center;
  width: 100%;

  button {
    border: 0;
    background: transparent;
    outline: none;
    cursor: pointer;
  }

  svg {
    font-size: 30px;
  }

  &__disabled {
    opacity: 0.5;

    button {
      cursor: default;
    }
  }
}

.spTable {
  div[role='rowgroup'] {
    height: calc(100vh - 420px);
  }
}

.productDraftTable {
  padding: 30px;
}

.logoUzSmall {
  width: 40px;
  height: 40px;
  object-fit: cover;
}

.view-container {
  margin-top: 40px;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  transition: background-color 5000s ease-in-out 0s;
}
