.iconContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.Large {
  height: 35px;
  width: 35px;
}

.Medium {
  height: 24px;
  width: 24px;
}

.Small {
  height: 20px;
  width: 20px;
}

.Inherit {
  height: 16px;
  width: 16px;
}
