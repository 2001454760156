.errorWrapper {
  position: relative;
  background: var(--mui-palette-grey-100);
  min-height: calc(100vh - 112px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-style: normal;
  .line {
    left: 0;
    right: 0;
    border: 1px dashed var(--mui-palette-Slider-infoTrack);
    position: absolute;
    margin-top: -127px;
  }
  .stroke {
    width: 612px;
    border: 2px dashed var(--mui-palette-Slider-infoTrack);
    border-radius: 20px;
    padding: 48px 0 32px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: var(--mui-palette-grey-100);
    z-index: 1;
  }
  h2 {
    font-style: normal;
    font-weight: 500;
    font-size: 200px;
    line-height: 210px;
    color: var(--mui-palette-Slider-infoTrack);
    margin: 0;
  }
  h3 {
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    text-transform: uppercase;
    margin: 0;
    color: var(--mui-palette-Slider-infoTrack);
  }
  p {
    margin: 32px 0 0;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: var(--mui-palette-text-primary);
    max-width: 616px;
    text-align: center;
  }
  .transfer {
    background: var(--mui-palette-primary-main);
    color: var(--mui-palette-primary-contrastText);
    border-radius: 8px;
    cursor: pointer;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    padding: 16px 24px;
    text-decoration: none;
    margin-top: 32px;
  }
}
