.container {
  font-size: 12px;
  width: 100%;

  :global(.MuiInputBase-input) {
    padding: 8px 12px 9px;
    height: 24px;
    font-size: 16px;
  }

  :global(.MuiInputLabel-root) {
    top: -8px;
  }

  :global(.MuiInputLabel-shrink) {
    top: 0;
    padding: 0 4px;
  }
}
