:global(.Toastify__toast-container--bottom-left) {
  bottom: 22px;
  left: 24px;
}

:global(.Toastify__toast-container) {
  padding: 0;

  :global(.Toastify__toast) {
    display: flex;
    align-items: center;
    box-sizing: border-box;
    padding: 12px;
    width: 100%;
    border-radius: 8px;
    height: 100%;
    color: #fff;
    min-height: 54px;
    margin-bottom: 4px;

    :global(.Toastify__close-button) {
      color: #fff;
      opacity: 1;
      align-self: center;
    }
  }

  .error-toast-container {
    background: #f34747;
  }

  .success-toast-container {
    background: #555d67;
  }

  :global(.Toastify__toast-body) {
    padding: 0;
    margin: 0;
  }

  .Toast-content {
    display: flex;
    box-sizing: border-box;
    height: 100%;
    color: #fff;
    font-size: 14px;
    padding: 0;
    margin: 0;
  }
}
