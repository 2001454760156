.filters {
  margin: 10px 0;

  .filtersContainer {
    height: max-content;
    background-color: var(--mui-palette-grey-50);
    margin-bottom: 30px;
    padding: 16px;

    .filtersContent {
      height: 85%;
    }

    .filterActions {
      display: flex;
      height: 42px;
      justify-content: flex-end;
      padding: 16px 0;
    }
  }
}

.modalContainer {
  display: flex;
  margin: 16px;
  height: 100vh;

  .content {
    display: flex;
    flex-direction: column;
  }

  .frame {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 15px;
  }

  .name {
    width: 450px;
    overflow-wrap: break-word;
  }

  .filtersContainer {
    display: flex;
    flex-direction: column;
    margin: 0 0 36px;
  }

  .filter {
    margin-top: 16px;
    max-width: 304px;
  }

  .actionButton {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin: auto 0 0;
  }
}

.applyButton {
  background-color: var(--mui-palette-primary-main);
}

.filterField {
  label {
    background: var(--mui-palette-grey-50);
  }
}

.icon {
  height: 19px;
  width: 19px;
}

.filtersInDrawer {
  width: 324px;
  max-width: 324px;
}

.refresh {
  width: 18px;
  margin-right: 10px;
}
