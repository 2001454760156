.fieldRequiredError {
  :global(.MuiInputBase-root) {
    position: relative;

    &::before {
      position: absolute;
      content: '';
      display: block;
      width: 7px;
      height: 100%;
      left: 0;
      border-left: 5px solid var(--mui-palette-error-main);
      border-radius: 8px;
    }
  }
}
