.dataTableWrap {
  button {
    svg {
      fill: #d3d3d3;
    }
  }
  div[role='table'] > div:nth-child(1) > div[role='row'] {
    cursor: default !important;
  }
  div[role='row'] {
    background-color: #f9f9f9;
    cursor: default;
  }
  div[role='row']:hover {
    background-color: #f9f9f9 !important;
    //cursor: pointer;
  }
  div[role='rowgroup'] div[role='row'] {
    background-color: #fff;
  }
  button {
    color: white;
  }
  img {
    display: none;
  }
}

.theme-kz button[style*='background: rgb(246, 244, 46)'] {
  background-color: #0287b0 !important;
  color: white !important;
}

.theme-uz button[style*='background: rgb(246, 244, 46)'] {
  background-color: #67ac5c !important;
  color: white !important;
}

.theme-kg button[style*='background: rgb(246, 244, 46)'] {
  background-color: #057ec6 !important;
  color: white !important;
}
.theme-am button[style*='background: rgb(246, 244, 46)'] {
  background-color: #707070 !important;
  color: white !important;
}

button[type='submit'] {
  color: white;
}
