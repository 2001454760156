:global(.MuiAutocomplete-popper) {
  .listbox {
    max-height: none;

    :global(.MuiAutocomplete-option) {
      /* max-height: 50px; */
      flex-flow: column;
      align-items: start;
    }
  }
}

.anchor {
  font-size: 12px;

  :global(.MuiInputBase-root) {
    padding: 2px 6px;
    font-size: 16px;

    :global(.MuiChip-root) {
      background-color: var(--mui-palette-primary-light);
      color: var(--mui-palette-common-white);

      svg {
        color: var(--mui-palette-common-white);
      }
    }
  }

  :global(.MuiInputLabel-shrink) {
    top: 0;
  }
}
