.fieldRequiredError {
  :global(.MuiInputBase-root) {
    position: relative;
    padding-left: 0;

    &::before {
      position: absolute;
      content: '';
      display: block;
      width: 4px;
      height: 100%;
      left: 0;
      border-left: 5px solid var(--mui-palette-error-main);
      border-radius: 8px;
    }
  }
}

.disabled {
  :global(.MuiInputAdornment-root) {
    svg {
      color: var(--mui-palette-grey-50);
      cursor: initial;
    }
  }
}

.hasError {
  :global(.MuiInputAdornment-root) {
    svg {
      color: var(--mui-palette-error-main);
    }
  }
}
