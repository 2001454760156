.fileLoading {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;

  &__back {
    background: var(--mui-palette-common-black);
    opacity: 0.5;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 3;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__text {
    background-color: var(--mui-palette-common-white);
    padding: 20px;
    font-size: 16px;
    border-radius: 4px;
    z-index: 4;
  }
}

.hidden {
  height: 0;
}

.info {
  height: 100px;
  gap: 8px;
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: 500;
  color: var(--mui-palette-info-main);
  padding: 6px 16px;
  border-radius: 4px;
  background: rgba(0, 87, 178, 0.04);
}

.icon {
  width: 45px;
}

.order-id-container {
  display: flex;
  align-items: center;
}
