.container {
  &:global(.MuiChip-root.MuiChip-filled) {
    color: var(--mui-palette-secondary-contrastText);
    padding: 8px 12px;
    height: auto;
    border-radius: 100px;
    &:global(.MuiChip-filledError) {
      background-color: var(--mui-palette-error-main);
    }
    &:global(.MuiChip-filledDefault) {
      background-color: var(--mui-palette-primary-main);
    }
    &:global(.MuiChip-filledSuccess) {
      background-color: var(--mui-palette-success-main);
    }
    &:global(.MuiChip-filledInfo) {
      background-color: var(--mui-palette-info-main);
    }
    &:global(.MuiChip-filledWarning) {
      background-color: var(--mui-palette-warning-main);
    }
    :global(.MuiChip-label) {
      font-size: 14px;
      line-height: 20px;
    }
    :global(.MuiChip-icon) {
      margin: 0;
      flex-shrink: 0;
      margin-right: 4px;
      color: var(--mui-palette-common-white);
    }
  }
  &_small {
    &:global(.MuiChip-root.MuiChip-filled) {
      padding: 7px 12px;
      :global(.MuiChip-label) {
        font-size: 13px;
        line-height: 18px;
      }
    }
  }
}
