.pg-cell {
  align-items: center;
  display: grid;
  grid-template-columns: 30px calc(100% - 30px);
  column-gap: 1px;
  width: 100%;

  svg {
    color: var(--mui-palette-grey-600);
    width: 25px;
    height: 25px;
  }
}
