.formControlWithError {
  :global(.MuiInputLabel-root) {
    color: var(--mui-palette-error-main);
  }
}

.fieldRequiredError {
  position: relative;
  padding: 0;

  :global(.MuiInputBase-root) {
    &::before {
      position: absolute;
      content: '';
      display: block;
      width: 7px;
      height: 100%;
      left: 0;
      border-left: 5px solid var(--mui-palette-error-main);
      border-radius: 8px;
    }
  }
}
