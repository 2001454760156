.MainMenuRoot {
  background-color: var(palette-AppBar-defaultBg);
  padding: 0 24px;
  display: flex;
  align-items: center;
  user-select: none;
  min-height: 56px;
}

.AppMainMenuItems {
  display: flex;
  align-items: center;
  flex: 1 1 auto;
  gap: 16px;
}

.AppMainMenuLogo {
  flex: 0 0 auto;
  padding-right: 24px;
}

.AppMainMenuItem {
  height: 56px;
  padding: 0 8px !important;
  flex: 0 0 auto;
  gap: 12px;
  display: flex;
  align-items: center;
  color: var(--mui-palette-text-primary);
  cursor: pointer;
}

.AppMainMenuItem:hover {
  color: var(--mui-palette-primary-main);
}

.AppMainMenuItemSelected {
  color: var(--mui-palette-primary-main) !important;
  background-color: var(--mui-palette-action-selected) !important;
  border-radius: 8px !important;
}

.AppMainMenuItemOpen {
  background-color: var(--mui-palette-grey-0);
}

.AppMainMenuItemDropdown svg {
  width: 20px;
  height: 20px;
}

.AppMainMenuItemDropdown {
  border-radius: 0 0 8px 8px;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.08);
  min-width: 282px;
}

.AppMainMenuItemIcon {
  flex: 0 0 auto;
  display: flex;
  align-items: center;
}

.AppMainMenuItemLabel {
  flex: 0 0 auto;
  line-height: 20 / 14;
  font-size: 14px;
  font-weight: 400;
  display: flex;
  align-items: center;
}

.AppMainMenuSpace {
  flex: 1 1 auto;
  margin-left: -16px;
}

.MainMenuContainer {
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.04);
}

.DropDownMarkerRoot {
  width: 16px;
  height: 16px;
  padding: 4px 0;
  margin-left: 4px;
  display: inline-flex;
  justify-content: center;
  box-sizing: border-box;
}

.DropDownMarkerRoot::after {
  content: '';
  display: block;
  transform: rotate(-45deg);
  width: 0;
  height: 0;
  border-width: 3px;
  border-style: solid;
  border-top-color: transparent;
  border-right-color: transparent;
}

.DropDownMarkerRootOpen {
  transform: rotate(-180deg);
}
