.container {
  height: 36px;
  :global(.Mui-selected) {
    background: var(--mui-palette-primary-main);
    color: var(--mui-palette-primary-contrastText);
  }
  button {
    border: 1px solid var(--mui-palette-primary-main);
    white-space: nowrap;
    font-size: 14px;
  }
}
