.commonDialog {
  &__container {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    :global(.MuiIconButton-root) {
      color: var(--mui-palette-grey-600);
      svg {
        width: 20px;
        padding: 3px 0 0 4px;
      }
    }
  }

  &__title {
    font-size: 24px;
  }

  :global(.MuiDialog-paper) {
    background: var(--mui-palette-common-white);
    border-radius: 5px;
  }
  :global(.MuiFormControlLabel-label) {
    font-weight: 400;
  }
  :global(.MuiDialogActions-root) {
    padding: 0 20px 20px;
    justify-content: flex-end;
  }
}
