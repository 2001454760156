.drawer {
  display: flex;
  flex-direction: column;

  &-header {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 32px;

    &__icon {
      color: var(--mui-palette-common-black);
    }

    img {
      width: 225px;
    }
  }

  &-content {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 0 32px;
    box-sizing: border-box;
    flex: 1 1 100%;
  }

  &-footer {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;

    .icon_logout {
      color: var(--mui-palette-common-black);
      width: 24px;
      height: 24px;
    }
    .logout:hover .icon_logout {
      fill: var(--mui-palette-primary-dark);
    }
    .logout {
      padding: 16px 32px 32px;
      margin-left: -6px;
      margin-top: -20px;
      cursor: pointer;
      display: flex;
      align-items: center;
      font-size: 16px;
    }
  }
}
