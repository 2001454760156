.textField {
  font-size: 12px;
  :global(.MuiInputBase-root) {
    padding-right: 0;
  }
  :global(.MuiInputBase-input) {
    padding: 8px 12px;
    height: 24px;
    font-size: 16px;
  }
  :global(.MuiFormLabel-root) {
    top: -7px;
  }
  :global(.MuiInputLabel-shrink) {
    top: 0;
  }

  .clear-button {
    visibility: hidden;
    position: absolute;
    right: 0;
    color: var(--mui-palette-action-active);
    margin-right: 8px;
    svg {
      width: 20px;
      height: 20px;
    }
  }
  &:not(.empty) {
    &.clearable {
      &:hover :global(.MuiInputBase-input),
      :global(.MuiInputBase-input):focus {
        padding-right: 42px;
      }
    }
    &:hover .clear-button,
    :global(.MuiInputBase-input):focus ~ .clear-button {
      visibility: visible;
    }
  }
}

.error {
  :global(.MuiInputBase-root) {
    position: relative;
    &::before {
      position: absolute;
      content: '';
      display: block;
      width: 4px;
      height: 100%;
      left: 0;
      border-left: 5px solid var(--mui-palette-error-main);
      border-radius: 8px;
    }
  }
}
