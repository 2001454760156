.PageMenu {
  padding: 0 24px;
  :global(.MuiTabs-indicator) {
    display: none;
  }
  :global(.Mui-selected) {
    color: var(--mui-palette-text-primary);
    border: 0;
    border-bottom: 3px solid var(--mui-palette-primary-main);
  }
  button {
    margin-right: 32px;
    padding: 18px 0;
    text-transform: unset;
    font-size: 14px;
    line-height: 20px;
    color: var(--mui-palette-text-secondary);
    &:last-child {
      margin-right: unset;
    }
  }
  :global(.MuiTab-textColorPrimary.Mui-selected) {
    color: var(--mui-palette-primary-main);
  }
}
