.stepsContainer {
  border-radius: 8px;
  background: var(--secondary-states-hover, rgba(156, 39, 176, 0.04));
  padding: 32px;
  box-sizing: border-box;
  min-width: 420px;
  margin: 0 0 auto 32px;
  :global(.MuiStepLabel-label) {
    font-size: 14px;
    cursor: default;
  }
  :global(.MuiStepLabel-label.Mui-active) {
    color: var(--mui-palette-text-primary);
    cursor: default;
  }
  :global(.MuiStepLabel-label.Mui-disabled:not(.Mui-active)) {
    opacity: 0.5;
    cursor: initial;
    color: var(--mui-palette-text-secondary);
  }
  :global(.MuiStepConnector-line) {
    min-height: 176px;
  }
}

.Stepper {
  :global(.MuiStep-root) {
    :global(.MuiStepLabel-labelContainer) {
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 157%;
      letter-spacing: 0.1px;
    }
  }
  svg {
    width: 24px;
    height: 24px;
    border-radius: 24px;
    text {
      font-size: 12px;
    }
  }
  :global(.MuiSvgIcon-root.Mui-completed),
  :global(.MuiSvgIcon-root.Mui-active) {
    color: var(--mui-palette-primary-main);
  }
}
