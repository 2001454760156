.viewWrapper {
  position: relative;
  min-height: calc(100vh - 170px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 15px;
  &__headerActions {
    display: flex;
    gap: 10px;
    align-items: center;
  }
}

.viewWrapper_full {
  min-height: calc(100vh - 140px);
}

.form {
  padding: 20px 30px;
}

.viewWrapper__footer {
  padding: 10px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0 -4px 12px 0 var(--mui-palette-action-hover);

  button {
    margin-left: 15px;
  }
}

.viewWrapper__spinner {
  position: absolute;
  top: 50%;
  left: 50%;
}

.viewWrapper__title {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  span {
    text-transform: initial;
  }

  button {
    margin: 0;
  }
}

.viewWrapper__edit {
  color: var(--mui-palette-primary-main);
  padding: 5px;
  cursor: pointer;

  g {
    fill: var(--mui-palette-primary-main);
  }

  svg {
    font-size: 23px;
  }
}

.viewWrapper__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;

  h2 {
    font-size: 36px;
    max-width: 720px;
    white-space: nowrap;
  }
}

.viewWrapper__header_full {
  h2 {
    max-width: none;
  }
}

.backArrowButton {
  background-color: transparent;
  border: 0;
  cursor: pointer;
  margin: 0;

  svg {
    width: 20px;
  }
}

.titleFont {
  font-size: 36px;
  font-weight: 500;
  line-height: 116.7%; /* 42.012px */
  letter-spacing: -1.5px;
  color: var(--mui-palette-text-primary);
  font-family: 'Roboto';
  margin: 0;
}

.subTitleFont {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 175%; /* 28px */
  letter-spacing: 0.15px;
  color: var(--mui-palette-text-primary);
  font-family: 'Roboto';
  margin: 0;
}

.cancelSubmitBtns {
  height: 45px;
  display: flex;
}

.wrapper__disabled {
  display: none;
}

.wrapper__main {
  display: grid;
  gap: 20px;
  grid-template-columns: calc(100% - 270px) 250px;
  > div {
    margin: 0;
    &:nth-child(2) {
      height: 320px;
    }
  }
}

.wrapper__content {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.wrapper__stepper {
  > div {
    min-width: auto;
    margin: 0;
  }
}
