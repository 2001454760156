.container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  box-sizing: border-box;
  min-width: 332px;
}

.header {
  border-bottom: 1px solid var(--mui-palette-grey-300);
  padding: 16px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
}

.content {
  padding: 16px;
  overflow-y: auto;
  flex: 1 1 100%;
  box-sizing: border-box;
}

.footer {
  display: flex;
  justify-content: flex-end;
  padding: 16px;
  box-sizing: border-box;

  .cancel {
    margin-right: 16px;
  }
}
