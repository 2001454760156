.infoNotification {
  box-sizing: border-box;

  :global(.MuiPaper-root) {
    padding: 20px 24px;
    border-radius: 8px;
    min-height: 40px;
    box-sizing: border-box;
    background: var(--mui-palette-Alert-infoStandardBg);
    border: 0;
  }

  &__content {
    display: flex;
    align-items: center;
    height: 32px;
  }

  &__icon {
    display: flex;
    align-items: center;
    margin-right: 20px;
    color: var(--mui-palette-Alert-infoIconColor);

    svg {
      width: 32px;
    }
  }

  &__text {
    display: flex;
    align-items: center;
    min-height: 40px;
    box-sizing: border-box;
    color: var(--mui-palette-info-main);
  }

  .title {
    margin-right: 5px;
  }
}

.error {
  :global(.MuiPaper-root) {
    padding: 18px;
    background: var(--mui-palette-Alert-errorStandardBg);
  }

  .infoNotification__icon {
    color: var(--mui-palette-text-secondary);
  }

  .infoNotification__text {
    color: var(--mui-palette-text-primary);
  }
}
