/* no important */
.theme-am {
  .MuiSwitch-colorPrimary.Mui-checked {
    color: var(--mui-palette-primary-main);
  }

  .MuiSwitch-colorSecondary.Mui-checked {
    color: var(--mui-palette-primary-main);
  }

  .MuiSwitch-track {
    background-color: var(--mui-palette-primary-main) !important;
  }

  .MuiButton-textSecondary {
    background-color: transparent;
    color: var(--mui-palette-secondary-main);

    &:hover {
      background-color: var(--mui-palette-action-hover);
    }
  }

  .MuiButton-textPrimary {
    background-color: transparent;
    color: var(--mui-palette-primary-main);

    &:hover {
      background-color: var(--mui-palette-action-hover);
    }
  }

  a,
  a > span {
    color: var(--mui-palette-info-main);

    &:hover {
      color: var(--mui-palette-info-dark);
    }
  }

  .MuiTabs-root {
    a {
      color: inherit;
    }
  }

  .MuiFormLabel-colorPrimary.Mui-focused {
    color: var(--mui-palette-primary-main);
  }

  .MuiInputBase-colorPrimary.Mui-focused fieldset {
    border-color: var(--mui-palette-primary-main);
  }

  .MuiInputBase-root {
    &::after {
      border-color: var(--mui-palette-primary-main);
    }
  }
  .MuiMenuItem-root {
    &:hover {
      background-color: var(--mui-palette-action-hover);
    }
  }

  .MuiMenuItem-root.Mui-selected {
    background-color: var(--mui-palette-action-selected);
    color: var(--mui-palette-text-main);
    &:hover {
      background-color: var(--mui-palette-action-hover);
    }
  }
}
