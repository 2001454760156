.container {
  width: 100%;
  height: max-content;
  display: flex;
  justify-content: center;

  &:global(.MuiCircularProgress-colorPrimary) {
    color: var(--mui-palette-primary-main);
  }
}
