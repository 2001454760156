.container {
  display: unset;
  :global(.MuiButton-containedPrimary) {
    background-color: var(--mui-palette-primary-main);
    &:hover {
      border-color: var(--mui-palette-primary-dark);
      background-color: var(--mui-palette-primary-dark);
    }
  }
  :global(.Mui-disabled) {
    background-color: var(--mui-palette-action-disabledBackground);
  }
  //:global(.MuiButton-outlined) {
  //  background-color: transparent;
  //  color: var(--mui-palette-primary-main);
  //}
  //:global(.MuiButton-text) {
  //  background-color: transparent;
  //}
  :global(.MuiButton-containedSecondary) {
    background-color: var(--mui-palette-secondary-main);
    &:hover {
      border-color: var(--mui-palette-secondary-main);
      background-color: var(--mui-palette-secondary-main);
    }
  }
  :global(.MuiButton-outlinedSecondary) {
    background-color: transparent;
    color: var(--mui-palette-secondary-main);
    border-color: var(--mui-palette-secondary-dark);
    &:hover {
      background-color: var(--mui-palette-action-hover);
      border-color: var(--mui-palette-secondary-main);
    }
  }
  :global(.MuiButton-outlinedPrimary) {
    background-color: transparent;
    color: var(--mui-palette-primary-main);
    border-color: var(--mui-palette-primary-light);
    &:hover {
      background-color: var(--mui-palette-action-hover);
      border-color: var(--mui-palette-primary-main);
    }
  }
  :global(.MuiButton-textSecondary) {
    background-color: transparent;
    color: var(--mui-palette-secondary-main);
    &:hover {
      background-color: var(--mui-palette-action-hover);
    }
  }
  :global(.MuiButton-textPrimary) {
    background-color: transparent;
    color: var(--mui-palette-primary-main);
    &:hover {
      background-color: var(--mui-palette-action-hover);
    }
  }
  :global(.Mui-disabled) {
    color: var(--mui-palette-action-disabled);
  }
}
