.wrapper {
  display: flex;
  align-items: center;
  .crumbLink {
    color: var(--mui-palette-primary-main);
    font-size: 14px;
    text-decoration: none;
  }
  .crumbText {
    color: var(--mui-palette-primary-main);
    font-size: 14px;
    font-weight: 600;
  }
  .icon {
    color: var(--mui-palette-primary-main);
    height: 20px;
  }
}
