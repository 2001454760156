.paginationTable {
  width: 100%;
  display: flex;
  justify-content: center;

  :global(.MuiButtonBase-root) {
    font-weight: 600;
    font-size: 12px;
    border: 0;
    align-items: center;
    text-transform: uppercase;
    margin: 0 10px;
  }
}
:global(.MuiTableSortLabel-icon) {
  opacity: 0.3;
}

.tableWrap {
  :global(.MuiSvgIcon-root) {
    color: var(--mui-palette-primary-main);
  }

  :global(.MuiCircularProgress-colorPrimary) {
    color: var(--mui-palette-primary-main);
  }
}

.icon {
  width: 14px;
  height: 14px;
  color: var(--mui-palette-primary-main);
}
