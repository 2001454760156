.container {
  :global(.MuiSelect-select) {
    color: var(--mui-palette-text-primary);
    text-transform: uppercase;
    font-weight: 700;
    font-size: 12px;
  }
  fieldset {
    display: none;
  }
}

.menu-item:global(.MuiMenuItem-root) {
  text-transform: uppercase;
  min-height: 52px;
  justify-content: center;
  font-weight: 700;
  font-size: 12px;
  display: flex;
  &:global(.Mui-selected) {
    &:hover,
    &:global(.Mui-focusVisible) {
      cursor: default;
      background-color: var(--mui-palette-action-focus);
    }
  }
}
